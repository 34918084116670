import { TeamBG } from "../../images/Services/Backgrounds"

import Map from "../../images/Services/DedicatedTeam/map.png"

const dataForDedicatedTeam = {
  service: "dedicatedteam",
  header: {
    title: "Dedicated development team",
    background: TeamBG,
  },
  articles: {
    image: Map,
    text: [
      "<strong></strong><strong>Hiring a dedicated team at OS-system means that the team members work exclusively on your project.</strong><br/><br/> We will use a plain pricing model (fixed flat monthly rate) to help you forecast your expenses. This approach makes a perfect model for any development team seeking to efficiently manage their budget. This approach is ideal for companies that have an ongoing need for development resources. ",
    ],
  },
}

export default dataForDedicatedTeam
