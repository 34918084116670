import React from "react"

import { Link } from "gatsby"

import uiuxData from "../../../information/UiUxDesign/dataForUiUx"

import "./uiUxLatestProject.scss"

class UxuiLatestProject extends React.Component {
  renderLatestProjectBlock = ({ id, image, title, desc, link }) => (
    <div key={id} className="latestProject-flex-item">
      <img
        className="latestProject-flex-item-img"
        src={image}
        alt="Our project"
      />
      <h3> {title} </h3>
      <p className="">{desc}</p>
      <Link to={link}>more</Link>
    </div>
  )

  render() {
    return (
      <section className="latestProject-parent container">
        <h2>Work projects</h2>
        <div className="latestProject-flex">
          {uiuxData.latestProject.map(current =>
            this.renderLatestProjectBlock(current)
          )}
        </div>
      </section>
    )
  }
}
export default UxuiLatestProject
