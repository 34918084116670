import React from "react"

import { Link } from "gatsby"

import "./ServicesHeader.scss"

export default function ServiceHeader({ title, BG, css }) {
  return (
    <section
      className={"services-header " + css}
      style={{ backgroundImage: `url(${BG})` }}
    >
      <div className="container">
        <div className="header row ">
          <h1 className="header-title col-lg-10 col-md-10 col-sm-11">{title}</h1>
          {css === "design" ? (
            <ul className="col-lg-7 col-md-7 col-sm-12 col-xs-12 portfolio-header-container-nav">
              <li className="">
                <Link to="/cases/" activeClassName="active">
                  #all
                </Link>
              </li>
              <li className="">
                <Link to="/cases/?platform=desktop/" activeClassName="active">
                  #desktop
                </Link>
              </li>
              <li className="">
                <Link to="/cases/?platform=mobile/" activeClassName="active">
                  #mobile
                </Link>
              </li>
              <li className="">
                <Link to="/cases/?platform=web/" activeClassName="active">
                  #web
                </Link>
              </li>
              <li className="">
                <Link to="/cases/?platform=webRTC/" activeClassName="active">
                  #WebRTC
                </Link>
              </li>
              <li className="">
                <Link to="/cases/design/" className={"active " + css}>
                  #design
                </Link>
              </li>
            </ul>
          ) : null}
        </div>
      </div>
    </section>
  )
}
