import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import HeaderSection from "../../components/Services/ServiceHeader"
import ServiceDescription from "../../components/SingleService/Common/ServiceDescription"
import Team from "../../components/SingleService/DedicatedTeam/TeamRoadmap"
import LatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import WorkTogetherSection from "../../components/Services/WorkTogetherSection"
import dataForDedicatedTeam from "../../information/Services/dataForDedicatedTeam"
import { mainUrl } from "../../js/config"
import "../../components/SingleService/DedicatedTeam/TeamRoadmap.scss"

export default function Startups() {
  const { header, articles } = dataForDedicatedTeam
  return (
    <section className="startup">
      <SEO
        title="Hire Dedicated Developers for Startup"
        description="If you want to hire a dedicated development team, OS-System Software Engineers are the perfect choice. 40+ people in the team to help your business grow."
        canonical={`${mainUrl}/services/dedicatedteam/`}
      />
      <Layout showFormPoint={100}>
        <HeaderSection title={header.title} BG={header.background} />
        <ServiceDescription articles={articles} />
        <Team />
        <LatestProjects />
        <WorkTogetherSection />
      </Layout>
    </section>
  )
}
