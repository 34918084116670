import React from "react"

import "./ServiceDescription.scss"

class ServiceDescription extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { articles } = this.props
    return (
      <section className="single-service-articles">
        <div className="container">
          <div className="row-flex">
            {articles.image ? (
              <div className="articles-wrapper col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <div className="image-scrum">
                  <img src={articles.image} alt='QA perfomance' />
                </div>
              </div>
            ) : null}
            {articles.text.map((article, idx) => (
              <div
                key={idx}
                className="articles-wrapper col-lg-5 col-md-5 col-sm-12 col-xs-12"
              >
                <article>
                  <p dangerouslySetInnerHTML={{ __html: article }} />
                </article>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default ServiceDescription
