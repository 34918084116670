import React from "react"

import { Link } from "gatsby"

import "./WorkTogetherSection.scss"

const WorkTogetherSection = () => {
  return (
    <section className="home-work-together">
      <div className="container">
        <div className="title">
          <h2>
            Already have an idea? <div className="xs-hidden"></div>
            Click <Link to="/contact/">here</Link> to discuss your project!
          </h2>
        </div>
      </div>
    </section>
  )
}

export default WorkTogetherSection
