import React from "react"

import TeamRoadmapSmall from "../../../../images/Services/DedicatedTeam/team-roadmap-small.png"
import TeamRoadmap from "../../../../images/Services/DedicatedTeam/team-roadmap.png"

export default function Team() {
  return (
    <section className="dedicatedteam">
      <div className="container">
        <div className="row dedicated">
          <div className="img-wrapper col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
            <picture>
              <source srcSet={TeamRoadmapSmall} media="(max-width: 480px)" />
              <source srcSet={TeamRoadmap} />
              <img src={TeamRoadmap} alt="Team roadmap" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  )
}
