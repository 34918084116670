import CRMSAP from "../../images/cases/casesCover/CRMSAP Integration.svg"
import CityCouncilPortal from "../../images/cases/casesCover/City Council Portal.svg"

const uiuxData = {
  uiux_2nd: [
    {
      id: 1,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc:
        "Increase conversation rates through more site interaction and customers getting to know your brand",
    },
    {
      id: 2,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc: "Ensure excellent customer retention and maximise your bottom line",
    },
    {
      id: 3,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc:
        "Implement UX early to prevent usability issues and costly redesigns",
    },
    {
      id: 4,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc:
        "Create products that people want to use because they are intuitive and simple",
    },
    {
      id: 5,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc: "Save costs on resources by encouraging serf-service",
    },
    {
      id: 6,
      tick: "../../vendors/images/UIUXDesign/tick.png",
      desc: "Improve your SEO rating and increase organic search results",
    },
  ],
  uiUxDetailList: [
    {
      key: 1,
      title: {
        label: "User Experience",
        className: "title-left",
      },
      articles: {
        labels: [
          {
            label: "Personal Definition",
            key: 1,
          },
          {
            label: "User Stories & Scenarios",
            key: 2,
          },
          {
            label: "Interaction Design",
            key: 3,
          },
          {
            label: "Clickable Prototypes",
            key: 4,
          },
          {
            label: "Usability Testing",
            key: 5,
          },
        ],
        className: "list-left",
      },
      className: "flex-item-left",
    },
    {
      key: 2,
      title: {
        label: "User Interface",
        className: "title-right",
      },
      articles: {
        labels: [
          {
            label: "Information Architecture",
            key: 1,
          },
          {
            label: "Wireframe Strategy",
            key: 2,
          },
          {
            label: "User Interface",
            key: 3,
          },
          {
            label: "Trending Design",
            key: 4,
          },
          {
            label: "Style Guide",
            key: 5,
          },
        ],
        className: "list-right",
      },
      className: "flex-item-right",
    },
  ],
  latestProject: [
    {
      id: 1,
      image: CRMSAP,
      title: "CRM/SAP Integration",
      desc:
        "A web application with a high level of integration with SAP Business One that allows automated booking of IP-calling or any other products.",
      link: "/cases/crm-sap-integration",
    },
    {
      id: 2,
      image: CityCouncilPortal,
      title: "City Council Portal",
      desc:
        "A government project that monitors latest developments in the city and provides access to public services for citizens and tourists.",
      link: "/cases/city-portal",
    },
  ],
}

export default uiuxData
